.App {
  text-align: center;
}

html,
body {}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.video-container {
  margin: 0 auto;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.video-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: url(./image/poster1.png) no-repeat center center/cover;
}

video {
  min-width: 100%;
  min-height: 100vh;
  position: absolute;
}

/*画面サイズが変わっても常に動画の中央が表示されるようにする*/
/*動画よりも画面が横に長くなるとき用*/
@media (aspect-ratio: 16/9),
(min-aspect-ratio: 16/9) {
  video {
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

/*動画よりも画面が縦に長くなるとき用*/
@media (max-aspect-ratio: 16/9) {
  video {
    height: 100%;
    height: 100vh;
    left: 50%;
    transform: translateX(-50%);
  }
}

.text1 {
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 110px;
  color: #fff;
  position: absolute;
  text-decoration: none;
  left: 5%;
  right: 5%;
  bottom: calc(45%);
  z-index: 1;
  text-align: center;
}

.text2 {
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 110px;
  color: #fff;
  position: absolute;
  text-decoration: none;
  left: 5%;
  right: 5%;
  bottom: calc(45%);
  z-index: 1;
  text-align: center;
  color: #fff;
  text-decoration: none;
  top: 100px;
  position: relative;
  font-size: 60px;
}

.link1 {
  display: inline-block;
  color: #fff;
  position: absolute;
  text-decoration: none;
  left: 55%;
  top: calc(55%);
  z-index: 1;
  opacity: 0.5;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link1:hover {
  color: rgb(255, 255, 255);
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.link2 {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  position: absolute;
  right: 55%;
  top: calc(55%);
  z-index: 1;
  opacity: 0.5;
  text-align: center;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.link2:hover {
  color: rgb(255, 255, 255);
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.link3 {
  text-decoration: none;
  color: #fff;
  position: absolute;
  right: 3%;
  bottom: 3%;
  z-index: 1;
  opacity: 0.5;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.link3:hover {
  color: rgb(255, 255, 255);
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media screen and (min-width: 921px) {
  .p1 {
    font-family: '筑紫A丸ゴシック', sans-serif;
    font-size: 43px;
    font-weight: bold;
  }

  .icon-shop {
    width: 70px;
  }
}

@media screen and (max-width: 920px) {
  .text1 {
    font-size: 45px;
    color: #fff;
    position: absolute;
    text-decoration: none;
    left: 5%;
    right: 5%;
    bottom: calc(48%);
    font-weight: bold;
  }

  .text2 {
    color: #fff;
    text-decoration: none;
    top: 100px;
    position: relative;
    font-size: 60px;
  }

  .link1 {
    left: 53%;
  }

  .link2 {
    right: 53%;
  }

  .link3 {
    /* right: 23%; */
  }

  .p1 {
    font-family: '筑紫A丸ゴシック', sans-serif;
    font-size: 25px;
    font-weight: bold;
  }

  .icon-shop {
    width: 40px;
  }
}